export const periodeList  = [
    {
      label: '1 month (4%)',
      duration:'30d',
    },
    {
      label: '3 months (15%)',
      duration:'90d',
    },
    {
      label: '5 Months (22%)',
      duration:'150d',
    },
    {
      label: '1 year (50%)',
      duration:'365d',
    },
  ];
  