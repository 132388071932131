export const list  = [
    {
      title: 'Stake your $FCC',
      subtitle:'Reward dQUICK.',
      icon:{
        stakeCoin:'assets/images/icon/icon_bnb.png',
        rewardCoin:'assets/images/icon/icon_bnb.png'
      },
      apy:{
        label:'APY',
        value:'13%',
      },
      totalStake:{
        label:'Total Stake',
        value:'100.000 BNB'
      },
      totalStaker:{
        label:'Total Staker',
        value:'410'
      },
      contract:'0x7f5af3d8c520f14194ef49459dcf99bcc2ca5502',
      link: '/staking/0x7f5af3d8c520f14194ef49459dcf99bcc2ca5502',
      status:'participate'
    },
    
  ];
  